<template>
  <div class="tools02-account-detail">
    <template
      v-if="
        $route.name === 'tools02-account-detail-tags' ||
        $route.name === 'tools02-account-detail-tag-detail'
      "
    >
    </template>
    <template v-else>
      <el-row>
        <el-col>
          <h2>
            <span v-if="tool.codeKebab === 'microsoft-cv'"
              >マネージャーアカウント</span
            >
            <span v-else>広告アカウント</span>
            <span v-if="accountId === '0'">新規登録</span>
            <span v-else>編集</span>
          </h2>
        </el-col>
      </el-row>

      <Tools02AccountDetailAccountEditor
        :accountId="accountId"
        style="margin-bottom: 20px"
      />

      <Tools02AccountDetailAutoUploadUrl
        v-if="
          accountId !== '0' &&
          (tool.codeKebab === 'google-cv' || tool.codeKebab === 'microsoft-cv')
        "
        :accountId="accountId"
      />
    </template>

    <template v-if="tool.codeKebab === 'yda-cv'">
      <template
        v-if="
          $route.name === 'tools02-account-detail-tags' ||
          $route.name === 'tools02-account-detail-tag-detail'
        "
      >
        <router-view :toolId="toolId" :accountId="accountId" />
      </template>
      <template>
        <!-- NO -->
      </template>
    </template>
    <template v-else>
      <div v-if="accountId !== '0'">
        <hr />
        <h2
          id="promotions"
          style="border-bottom: none; padding-bottom: 0; margin-bottom: 50px"
        >
          プログラム情報 編集
        </h2>
        <router-view :toolId="toolId" :accountId="accountId" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02AccountDetailAccountEditor from "@/components/Tools02/AccountDetail/AccountEditor.vue";
import Tools02AccountDetailAutoUploadUrl from "@/components/Tools02/AccountDetail/AutoUploadUrl.vue";

export default {
  components: {
    Tools02AccountDetailAccountEditor,
    Tools02AccountDetailAutoUploadUrl,
  },
  props: {
    toolId: String,
    accountId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "aspNames"]),
    ...mapGetters("subscription", ["validities"]),
  },
  watch: {},
  created() {
    console.log(this.$route);
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.letter {
  font-size: 14px;
}
</style>
