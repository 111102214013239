<template>
  <div class="tools02-promotions-promotion-list">
    <el-row :gutter="18">
      <el-col style="width: 170px">
        <a :href="toolData.manualData.promotionRegistrationManualUrl" target="_blank" rel="noopener noreferrer">
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between">
      <el-col :span="18">
        <el-button type="primary" @click="toDetail('0')" class="register-button">プログラム新規登録</el-button>
        <router-link :to="`/tools02/${toolData.codeKebab}/copy-promotion/${accountId}`" class="copy-button">
          <el-button type="success">既存のプログラム情報からコピー</el-button>
        </router-link>
      </el-col>
      <el-col :span="6">
        <el-input v-model="searchText" placeholder="プログラム検索"></el-input>
      </el-col>
    </el-row>
    <el-row v-if="userData.bonusPointData[tool.codeCamel].promotion === false">
      <el-col>
        <UiBonusAlert :toolCode="tool.codeCamel" :bonusCode="'promotion'" boxStyle="fat" />
      </el-col>
    </el-row>
    <el-row>
      <el-col class="table-scroll">
        <el-table :data="displayPromotionList">
          <el-table-column sortable prop="promotionName" label="プログラム名">
            <template slot-scope="scope">
              {{ scope.row.promotionName }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="aspPromotionName" label="ASPプログラム名">
            <template slot-scope="scope">
              {{ scope.row.aspPromotionName }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="cvName" v-if="tool.codeKebab === 'google-cv' ||
            tool.codeKebab === 'yahoo-cv'
          " label="オフラインCV名">
            <template slot-scope="scope">
              {{ scope.row.cvName }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="aspName" label="ASP">
            <template slot-scope="scope">
              {{ scope.row.aspName }}
            </template>
          </el-table-column>
          <el-table-column label="" width="90px">
            <template slot-scope="scope">
              <el-button @click="toDetail(scope.row.promotionId)" type="">編集</el-button>
            </template>
          </el-table-column>
          <el-table-column label="" width="90px">
            <template slot-scope="scope">
              <el-button @click="
                delPromotion(
                  scope.row.promotionId,
                  scope.row.aspPromotionName
                )
                " type="danger">削除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- <el-row type="flex" justify="space-between">
      <el-col v-show="disableSortMode === true" :span="18">
        <el-button type="primary" @click="toDetail('0')" class="register-button">プログラム新規登録</el-button>
        <router-link :to="`/tools02/${toolData.codeKebab}/copy-promotion/${accountId}`" class="copy-button">
          <el-button type="success">既存のプログラム情報からコピー</el-button>
        </router-link>
        <el-button @click="disableSortMode = false">並び替える</el-button>
      </el-col>
      <el-col v-show="disableSortMode === true" :span="6">
        <el-input v-model="searchText" placeholder="プログラム検索"></el-input>
      </el-col>
      <el-col v-show="disableSortMode === false">
        <el-button @click="registerSort()" type="primary">並び替え完了</el-button>
        <el-button @click="disableSortMode = true" type="info">キャンセル</el-button>
        <span class="sort-description">ドラッグ＆ドロップで入れ替え、「並び替え完了」で順序を確定します。</span>
      </el-col>
    </el-row>
    <el-row v-if="userData.bonusPointData[tool.codeCamel].promotion === false">
      <el-col>
        <UiBonusAlert :toolCode="tool.codeCamel" :bonusCode="'promotion'" boxStyle="fat" />
      </el-col>
    </el-row>
    <el-row>
      <el-col class="table-scroll">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>プログラム名</th>
              <th>ASPプログラム名</th>
              <th v-if="tool.codeKebab === 'google-cv' ||
      tool.codeKebab === 'yahoo-cv'
      ">
                オフラインCV名
              </th>
              <th>ASP</th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <Draggable v-model="displayPromotionList" tag="tbody" :disabled="disableSortMode">
            <tr v-for="(promotion, index) in displayPromotionList" :key="index"
              :class="{ 'sort-mode': disableSortMode === false }">
              <td>
                {{ promotion.promotionName }}
              </td>
              <td>
                {{ promotion.aspPromotionName }}
              </td>
              <td v-if="tool.codeKebab === 'google-cv' || tool.codeKebab === 'yahoo-cv'">
                {{ promotion.cvName }}
              </td>
              <td>
                {{ aspNames[promotion.aspId] }}
              </td>
              <td>
                <div v-show="disableSortMode === true">
                  <router-link v-if="tool.codeKebab === 'google-cv' ||
      tool.codeKebab === 'yahoo-cv' ||
      tool.codeKebab === 'microsoft-cv'
      " :to="`/tools02/${tool.codeKebab}/account-detail/${accountId}/promotion/${promotion.promotionId}`">
                    <el-button type="">編集</el-button>
                  </router-link>
                  <router-link v-else-if="tool.codeKebab === 'facebook-cv' ||
      tool.codeKebab === 'tiktok-cv' ||
      tool.codeKebab === 'line-cv' ||
      tool.codeKebab === 'yda-cv'
      " :to="`/tools02/${tool.codeKebab}/promotion-detail/${promotion.promotionId}`">
                    <el-button type="">編集</el-button>
                  </router-link>
                </div>
              </td>
              <td>
                <el-button v-show="disableSortMode === true" @click="
      delPromotion(
        promotion.promotionId,
        promotion.aspPromotionName
      )
      " type="danger">削除</el-button>
              </td>
            </tr>
          </Draggable>
        </table>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col>
        <el-button type="primary" @click="toDetail('0')" class="register-button">プログラム新規登録</el-button>
        <router-link :to="`/tools02/${toolData.codeKebab}/copy-promotion/${accountId}`">
          <el-button type="success">既存のプログラム情報からコピー</el-button>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Draggable from "vuedraggable";
import Button2 from "@/components/Ui/Button2.vue";
import UiBonusAlert from "@/components/Ui/BonusAlert.vue";

export default {
  name: "Tools02PromotionsPromotionList",
  components: {
    // Draggable,
    Button2,
    UiBonusAlert,
  },
  props: {
    accountId: String,
  },
  data() {
    return {
      searchText: "",
      disableSortMode: true,
      promotionList: [],
      displayPromotionList: [],
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "apiErrorMessage",
      "tools",
      "aspNames",
      "userData",
    ]),
    ...mapGetters("tools02", ["accountTypeNames"]),
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  watch: {
    accountId: {
      handler() {
        this.updateDisplayPromotionList();
      },
      immediate: true,
    },
    searchText: {
      handler() {
        this.updateDisplayPromotionList();
      },
      immediate: true,
    }
  },
  methods: {
    updatePromotionList() {
      // 
      let promotionList = [];
      if (
        this.tool.codeKebab === "google-cv" ||
        this.tool.codeKebab === "yahoo-cv" ||
        this.tool.codeKebab === "microsoft-cv"
      ) {
        promotionList = this.$store.getters["tools02/promotionsInAccount"](
          this.tools[this.tool.codeCamel].id,
          this.accountId
        );
      } else if (
        this.tool.codeKebab === "facebook-cv" ||
        this.tool.codeKebab === "tiktok-cv" ||
        this.tool.codeKebab === "line-cv" ||
        this.tool.codeKebab === "yda-cv"
      ) {
        promotionList = this.$store.getters["tools02/promotionsInTool"](
          this.tools[this.tool.codeCamel].id
        );
      } else {
        console.log("no action.");
      }

      // 
      let aspNameAddedPromotionList = [];
      for (const promotionData of promotionList) {
        // 
        if (this.$store.getters.aspDataFromAllAspList(promotionData.aspId) !== undefined) {
          // ok!
        } else {
          continue;
        }

        // 
        let aspNameAddedPromotionData = promotionData;

        // 
        aspNameAddedPromotionData.aspName = this.$store.getters.aspDataFromAllAspList(promotionData.aspId).aspName;

        // 
        aspNameAddedPromotionList.push(aspNameAddedPromotionData);
      }
      promotionList = aspNameAddedPromotionList;

      // 
      this.promotionList = promotionList;
    },
    updateDisplayPromotionList() {
      // 
      this.updatePromotionList();

      // 
      const searchText = this.searchText;

      // 
      const promotionList = JSON.parse(JSON.stringify(this.promotionList));
      let displayPromotionList = [];
      if (searchText !== "") {
        displayPromotionList = promotionList.filter(function (promotionData) {
          // 
          let hit = false;
          if (
            promotionData.promotionName.includes(searchText) === true ||
            promotionData.aspPromotionName.includes(searchText) === true ||
            promotionData.cvName.includes(searchText) === true
          ) {
            hit = true;
          } else {
            // no action.
          }

          // 
          if (promotionData.aspId !== '') {
            const aspData = this.$store.getters['aspData'](promotionData.aspId);
            if (aspData.aspName.includes(searchText) === true) {
              hit = true;
            } else {
              // no action.
            }
          } else {
            // no action.
          }

          // 
          return hit;
        }, this);
      } else {
        // console.log(promotionList)
        displayPromotionList = promotionList;
      }

      // 
      this.displayPromotionList = displayPromotionList;
    },
    toDetail(promotionId) {
      // 
      let path = `/tools02/${this.toolData.codeKebab}/promotion-detail/${promotionId}`;
      switch (this.toolData.codeKebab) {
        case 'google-cv':
        case 'yahoo-cv':
        case 'microsoft-cv':
          path = `/tools02/${this.toolData.codeKebab}/account-detail/${this.accountId}/promotion/${promotionId}`;
          break;

        default:
          // 
          break;
      }

      // 
      this.$router.push(path);
    },
    registerSort() {
      // 
      let idSortList = [];
      let sort = 0;
      for (const displayPromotionData of this.displayPromotionList) {
        idSortList.push({ id: displayPromotionData.promotionId, sort: sort })
        sort++;
      }

      // 
      let params = new URLSearchParams();
      params.append("id_sort_list", JSON.stringify(idSortList));
      this.$axios
        .post(`${this.rt}/l/api/tools02/promotion/register-sort`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("tools02/setWithTrashedPromotionList")
              .then(response => {
                console.log(response);
                this.disableSortMode = true;
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delPromotion(promotionId, aspPromotionName) {
      if (
        confirm(
          `ASPプログラム名「${aspPromotionName}」のプログラム情報を削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("promotion_id", promotionId);
        this.$axios
          .post(`${this.rt}/l/api/tools02/promotion/delete`, params)
          // .post(`${this.rt}/api/tools02/delPromotion.php`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("tools02/setWithTrashedPromotionList");
              return;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-button,
.copy-button {
  margin-right: 15px;
}

.sort-description {
  margin-left: 20px;
}

.table-scroll {
  // @media screen and (max-width: 1200px){
  // height: 70vh;
  // overflow-x: scroll;
  // }
  overflow: auto;

  table {
    width: 100%;

    tbody {
      tr.sort-mode {
        cursor: move;

        &:active {
          cursor: grabbing;
        }
      }
    }
  }
}
</style>
